<template>
  <div class="custom-timeline relative">
    <div v-for="(item, index) in sortedItems" :key="index" class="intro-y timeline-item pb-8 last:pb-0 flex items-center">
      <div class="timeline-marker left-0 w-4 h-4 rounded-full border-2 border-white shadow-md mr-2 z-10" :style="{ backgroundColor: item.status.bgColor }"></div>
      <div class="timeline-content bg-white lb-shadow p-4 rounded-lg shadow-sm w-full">
        <span class="text-sm text-gray-500">{{ formatDate(item.timestamp) }}</span>
        <h3 class="text-lg font-semibold mt-1">{{ trans(item.status.name) }}</h3>
        <p class="text-gray-600 mt-1" v-if="item.comment">{{ item.comment }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { formatDate } from '../../../../base';
import { trans } from "../../../../js/utilities/leakblock-translations";

const props = defineProps({
  items: {
    type: Array,
    required: true
  }
});

const sortedItems = computed(() => {
  return [...props.items].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
});
</script>

<style scoped>
.timeline-item::before {
  content: '';
  @apply absolute left-[7px] top-0 bottom-0 w-0.5 bg-gray-200;
}
</style>