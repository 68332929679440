import { createApp } from 'vue';
import router from './router';
import { initializeSessionChecker } from '../../js/utilities/axios-setup';

const userApp = createApp({});

userApp.use(router);

initializeSessionChecker(router);

userApp.directive("click-outside", {
    beforeMount: function(el, binding) {
        const ourClickEventHandler = event => {
            if (!el.contains(event.target) && el !== event.target) {
                binding.value(event);
            }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
    },
    unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
    }
});

userApp.mount('#app');