<template>
  <div :class="containerClasses">
    <div class="flex-none w-full">
      <h2 class="text-2xl font-bold mb-4">{{ trans('leakblock.stepTwo.title') }}</h2>
      <p class="text-gray-600 mb-4">
        {{ trans('leakblock.stepTwo.description') }}
      </p>
      <div class="space-y-4">
        <!-- Platform Selection and Username Input -->
        <div class="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
          <select
              v-model="selectedPlatformId"
              class="block w-full sm:w-1/2 px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
          >
            <option value="" disabled selected hidden>{{ trans('leakblock.stepTwo.selectPlatform') }}</option>
            <option v-for="platform in platforms" :key="platform.id" :value="platform.id">
              {{ platform.name }}
            </option>
          </select>

          <input
              type="text"
              v-model="username"
              class="block w-full sm:w-1/2 px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              :placeholder="trans('leakblock.stepTwo.enterUsername')"
              @input="updateProfileLink"
          />
        </div>

        <!-- Additional inputs based on selected platform -->
        <div v-if="selectedPlatformId" class="space-y-2">
          <!-- PornHub Account Type -->
          <div v-if="selectedPlatformId === 8" class="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
            <select
                v-model="phAccountType"
                class="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                @change="updateProfileLink"
            >
              <option value="pornstar">{{ trans('leakblock.stepTwo.pornhubAccountType.pornstar') }}</option>
              <option value="cam">{{ trans('leakblock.stepTwo.pornhubAccountType.cam') }}</option>
            </select>
          </div>

          <!-- Custom Platform Name and Link -->
          <div v-if="selectedPlatformId === 14" class="space-y-2">
            <input
                type="text"
                v-model="customPlatformName"
                class="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                :placeholder="trans('leakblock.stepTwo.enterPlatformName')"
            />
            <input
                type="text"
                v-model="profileLink"
                class="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                :placeholder="trans('leakblock.stepTwo.enterProfileLink')"
            />
          </div>

          <!-- Profile Link Preview -->
          <div v-if="profileLinkPreview && selectedPlatformId !== 14" class="text-sm text-gray-600">
            {{ trans('leakblock.stepTwo.linkPreview') }}
            <a :href="profileLinkPreview" target="_blank" class="text-primary-500 hover:underline">
              {{ profileLinkPreview }}
            </a>
          </div>
        </div>

        <!-- Add Account Button -->
        <div class="flex justify-end">
          <button @click="addLinkedAccount" class="flex items-center justify-center text-white bg-primary-500 hover:bg-primary-400 px-6 py-2.5 rounded-md">
            <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4"></path>
            </svg>
            {{ trans('leakblock.stepTwo.addAccount') }}
          </button>
        </div>
      </div>

      <hr class="h-px my-8 bg-gray-200 border-0">
    </div>
    <div class="overflow-y-scroll w-full h-full pt-4">
      <transition-group name="list" tag="div">
        <div
            v-for="(account, index) in props.formData.linkedAccounts"
            :key="account.platformId"
            class="flex flex-col w-full list-item list-none"
            :class="{
              'mb-[120px]': index === props.formData.linkedAccounts.length - 1 && props.formData.linkedAccounts.length > 0,
              'mb-4': index !== props.formData.linkedAccounts.length - 1
            }"
        >
          <div class="flex items-center w-full">
            <img :src="getPlatformIcon(account.platformId)" alt="" class="w-12 h-12 mr-4">
            <div class="flex flex-1 min-w-0">
              <div v-if="account.platformId === 14" class="flex items-center grow min-w-0">
                <div class="min-w-[9rem] max-w-[9rem] text-gray-700 truncate">{{ account.platformName }}</div>
                <div v-if="!isMobile" class="text-gray-700 truncate">{{ account.username }}</div>
              </div>
              <div v-else class="flex items-center grow min-w-0">
                <div class="min-w-[9rem] max-w-[9rem] text-gray-700 truncate">{{ getPlatformName(account.platformId) }}</div>
                <div v-if="!isMobile" class="grow text-gray-700 truncate">{{ account.username }}</div>
              </div>
            </div>
            <button @click="removeLinkedAccount(index)" class="ml-4 flex items-center text-primary-500 bg-white border border-primary-500 hover:bg-primary-500 hover:text-white focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none transition duration-300">
              {{ trans('leakblock.stepTwo.removeAccount') }}
            </button>
          </div>
          <div v-if="isMobile" class="linkMobilePlacement mt-4">
            <div class="text-gray-700 truncate">{{ account.username }}</div>
            <div class="text-gray-700 truncate">{{ account.profileLink }}</div>
          </div>
          <hr v-if="index !== props.formData.linkedAccounts.length - 1" class="h-px my-4 bg-gray-200 border-0">
        </div>
      </transition-group>
    </div>
    <div class="flex w-full bg-transparent absolute bottom-0 left-0 lb-transparent-background h-[90px] border-t border-gray-200 rounded-br-2xl">
      <hr class="h-px my-8 bg-gray-200 border-0 mx-[-2.5rem]">
      <button @click="prevStep" class="flex items-center text-primary-500 bg-white border border-primary-500 hover:bg-primary-500 hover:text-white focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm focus:outline-none transition duration-300 px-4 py-2 rounded-md self-center mr-auto ml-[7.5rem]">
        <svg class="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
        </svg>
        {{ trans('leakblock.stepTwo.backButton') }}
      </button>
      <button @click="nextStep" class="flex items-center transition text-white bg-primary-500 hover:bg-primary-400 px-4 py-2 rounded-md self-center ml-auto mr-10 font-medium">
        {{ trans('leakblock.stepTwo.nextButton') }}
        <svg class="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
        </svg>
      </button>
    </div>
    <Snackbar :message="snackbarMessage" :show="showSnackbar" @close="closeSnackbar"/>
  </div>
</template>

<script setup>
  import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
  import Snackbar from "../../../../components/Snackbar/Snackbar.vue";
  import { trans } from "../../../../../js/utilities/leakblock-translations";

  const props = defineProps({
    formData: Object,
    currentStep: Number,
    platforms: Array,
  });

  const emit = defineEmits(['next', 'prev']);

  const selectedPlatformId = ref('');
  const customPlatformName = ref('');
  const profileLink = ref('');
  const snackbarMessage = ref('');
  const showSnackbar = ref(false);
  const isMobile = ref(false);
  const username = ref('');
  const profileLinkPreview = ref('');
  const phAccountType = ref('pornstar');

  const updateProfileLink = () => {
    if (selectedPlatformId.value && selectedPlatformId.value !== 14 && username.value) {
      const platform = props.platforms.find(p => p.id === selectedPlatformId.value);
      if (platform && platform.urlTemplate) {
        let urlTemplate = platform.urlTemplate;
        if (selectedPlatformId.value === 8) { // PornHub
          urlTemplate = urlTemplate.replace('{accountType}', phAccountType.value);
        }
        profileLinkPreview.value = urlTemplate.replace('{username}', username.value);
        profileLink.value = profileLinkPreview.value;
      }
    } else {
      profileLinkPreview.value = '';
    }
  };

  watch([() => selectedPlatformId.value, () => phAccountType.value], () => {
    updateProfileLink();
  });

  watch(() => selectedPlatformId.value, () => {
    username.value = '';
    profileLinkPreview.value = '';
    profileLink.value = '';
    customPlatformName.value = '';
  });

  const triggerSnackbar = (message) => {
    snackbarMessage.value = message;
    showSnackbar.value = true;
  };

  const closeSnackbar = () => {
    showSnackbar.value = false;
  };

  const addLinkedAccount = () => {
    if (!selectedPlatformId.value) {
      triggerSnackbar(trans('leakblock.stepTwo.snackbar.selectPlatform'));
      return;
    }

    if (selectedPlatformId.value === 14) {
      if (!customPlatformName.value) {
        triggerSnackbar(trans('leakblock.stepTwo.snackbar.enterPlatformName'));
        return;
      }
      if (!profileLink.value) {
        triggerSnackbar(trans('leakblock.stepTwo.snackbar.enterProfileLink'));
        return;
      }
      if (!isValidUrl(profileLink.value)) {
        triggerSnackbar(trans('leakblock.stepTwo.snackbar.invalidProfileLink'));
        return;
      }
    } else {
      if (!username.value) {
        triggerSnackbar(trans('leakblock.stepTwo.snackbar.enterUsername'));
        return;
      }
    }

    const newAccount = {
      platformId: selectedPlatformId.value,
      platformName: selectedPlatformId.value === 14 ? customPlatformName.value : getPlatformName(selectedPlatformId.value),
      username: username.value,
      profileLink: selectedPlatformId.value === 14 ? profileLink.value : profileLinkPreview.value,
      phAccountType: selectedPlatformId.value === 8 ? phAccountType.value : null,
    };
    props.formData.linkedAccounts.push(newAccount);

    // Reset fields after adding
    selectedPlatformId.value = '';
    customPlatformName.value = '';
    username.value = '';
    profileLink.value = '';
    profileLinkPreview.value = '';
    phAccountType.value = 'pornstar';
  };

  const removeLinkedAccount = (index) => {
    props.formData.linkedAccounts.splice(index, 1);
  };

  const prevStep = () => {
    emit('prev');
  };

  const nextStep = () => {
    emit('next');
  };

  const getPlatformIcon = (platformId) => {
    const platform = props.platforms.find(p => p.id === platformId);
    return platform ? platform.icon : '/build/images/icons/social-media/generic-social-media.svg';
  };

  const getPlatformName = (platformId) => {
    const platform = props.platforms.find(p => p.id === platformId);
    return platform ? platform.name : 14;
  };

  const isValidUrl = (url) => {
    const regex = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-zA-Z0-9$_.+!#&]+)(:[a-zA-Z0-9$_.+!#&]+)?@)?((([a-zA-Z0-9.-]+)\\.[a-zA-Z]{2,6})|(([0-9]{1,3}\\.){3}[0-9]{1,3}))|localhost)' + // domain name or ip address
        '(:[0-9]{1,5})?' + // port
        '(\\/[-a-zA-Z0-9%_.~+]*)*' + // path
        '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' + // query string
        '(\\#[-a-zA-Z0-9_]*)?$', // fragment locator
        'i'
    );
    return regex.test(url);
  };

  const containerClasses = computed(() => {
    return {
      'w-full md:w-2/3 flex flex-col bg-white py-4 px-10 md:p-10 md:pb-0 justify-between items-center overflow-hidden relative rounded-bl-2xl rounded-br-2xl md:rounded-tr-2xl md:rounded-br-2xl md:rounded-tl-none md:rounded-bl-none lb-shadow md:shadow-none': true,
      'pb-[120px]': props.formData.linkedAccounts.length === 0,
      'pb-4': props.formData.linkedAccounts.length > 0,
    };
  });

  const updateIsMobile = () => {
    isMobile.value = window.innerWidth < 768;
  };

  onMounted(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    onUnmounted(() => {
      window.removeEventListener('resize', updateIsMobile);
    });
  });
</script>

<style scoped>
  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease;
  }

  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }

  .list-move, /* apply transition to moving elements */
  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease;
  }

  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }

  /* ensure leaving items are taken out of layout flow so that moving
     animations can be calculated correctly. */
  .list-leave-active {
    position: absolute;
  }

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
