<template>
  <div class="rounded-md lb-shadow xl:col-span-1 min-h-[460px] md:max-h-[600px] md:overflow-hidden relative">
    <h3 class="flex items-center text-lg font-semibold relative bg-white px-6 py-4 rounded-t-md">
      {{ trans('leakblock.selfReportedLastLeaks.title') }}
      <LeakblockTooltip
          :description="trans('leakblock.selfReportedLastLeaks.popoverDescription')"
      />
      <div class="scroll-hint-top absolute bottom-[-16px] left-0 right-0 h-4 bg-gradient-to-t from-gray-200 to-transparent opacity-0" :class="{
            'opacity-100': showScrollHintTop,
          }"></div>
    </h3>
    <OverlayScrollbarsComponent :options="options" :events="events" ref="osComponent" class="md:h-full md:overflow-y-scroll">
      <div id="fullWidthTabContent" class="px-3 md:px-6 pt-3 pb-[60px] md:pb-[120px]" ref="osComponentMaxScrollHeight" data-overlayscrollbars-initialize>
        <div v-if="isLoading" class="flex justify-center items-center h-64">
          <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
        <transition name="fade" mode="out-in">
          <div v-if="showForm" key="form">
            <SelfReportedLastLeaksForm :accountUrl="accountUrl" @cancel="showForm = false" @submitted="handleLeakSubmitted" />
          </div>
          <div v-else-if="leaks.length > 0" key="list" id="faq">
            <ul role="list">
              <li v-for="leak in leaks" :key="leak.id" class="intro-y">
                <router-link
                    :to="{ name: 'leakDetail', params: { lang: $route.params.lang, accountUrl: $route.params.accountUrl, leakId: String(leak.leak_id) } }"
                >
                  <div class="py-3 px-6 sm:py-4 bg-white rounded-md mb-3 transition duration-300 hover:scale-[1.02]">
                    <div class="flex items-center justify-between">
                      <div class="flex-grow min-w-0 mr-4"> <!-- Added flex-grow and min-w-0 -->
                        <div>
                          <div class="flex items-center mb-1">
                            <img
                                :src="`https://www.google.com/s2/favicons?domain=${encodeURIComponent(leak.link)}`"
                                :alt="`${leak.website} favicon`"
                                class="w-4 h-4 mr-2 flex-shrink-0"
                                @error="handleFaviconError"
                            />
                            <p class="font-medium text-gray-900 truncate" :title="leak.website">
                              {{ leak.website }}
                            </p>
                          </div>
                          <div v-if="leak.search_engine" class="flex items-center mb-1 text-gray-500 text-sm">
                            {{ trans('leakblock.lastLeaks.foundThrough') }}
                            <div class="flex items-center">
                              <img
                                  :src="leak.search_engine.favicon"
                                  :alt="`${leak.website} favicon`"
                                  class="w-4 h-4 ml-1 mr-0.5 flex-shrink-0"
                              />
                              {{ leak.search_engine.name }}
                            </div>
                          </div>
                          <div v-else class="text-gray-500 text-sm truncate" :title="leak.link">{{ leak.link }}</div>
                          <div class="text-gray-500 text-sm">{{ formatDate(leak.created_at) }}</div>
                          <div class="flex items-center text-gray-500 text-sm mt-2">
                            <span class="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full"
                                  :style="{ backgroundColor: leak.leak_status.bgColor, color: leak.leak_status.color }">
                              {{ trans(leak.leak_status.name) }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="flex-shrink-0"> <!-- Added flex-shrink-0 -->
                        <button class="text-gray-600 hover:text-primary-500 rounded-full p-2 transition duration-300" aria-label="View more information">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
          <div v-else key="empty" class="flex justify-center items-center h-64">
            <p v-if="isFreeTier" class="text-center text-gray-500 py-4">{{ trans('leakblock.selfReportedLastLeaks.noLeaksFoundNoSub') }}</p>
            <p v-else class="text-center text-gray-500 py-4">{{ trans('leakblock.selfReportedLastLeaks.noLeaksFound') }}</p>
          </div>
        </transition>
      </div>
    </OverlayScrollbarsComponent>
    <div class="flex w-full bg-transparent absolute bottom-0 left-0 lb-transparent-background h-[60px] border-t border-gray-200 rounded-b-md px-6 items-center">
      <div class="scroll-hint-bottom absolute top-[-16px] left-0 right-0 h-4 bg-gradient-to-t from-gray-200 to-transparent" :class="{
            'opacity-0': hideScrollHintBottom,
          }"></div>
      <div v-if="!isFreeTier" class="flex items-center min-w-0">
        <button @click="showForm = true" class="text-gray-600 hover:text-primary-500 font-medium rounded-full focus:outline-none transition duration-300">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
          </svg>
        </button>
      </div>
      <div class="flex-shrink-0 ml-auto">
        <router-link :to="{ name: 'leakOverview', params: { lang: $route.params.lang, accountUrl: accountUrl } }" class="inline-flex items-center p-2 text-xs font-medium uppercase rounded-lg text-primary-700 sm:text-sm hover:bg-gray-100">
          {{ trans('leakblock.lastLeaks.viewAll') }}
          <svg class="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
          </svg>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getLatestSelfReportedLeaks } from "../../router/api";
import {trans} from "../../../../js/utilities/leakblock-translations";
import { onBeforeMount, ref, watch, computed } from "vue";
import { formatDate } from "../../../../base";
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue";
import SelfReportedLastLeaksForm from "./SelfReportedLastLeaksForm.vue";
import LeakblockTooltip from "../Tooltip/LeakblockTooltip.vue";

const props = defineProps({
  accountUrl: String,
  isFreeTier: Boolean,
});

console.log("test");

const leaks = ref([]);
const showScrollHintTop = ref(false);
const hideScrollHintBottom = ref(false);
const osComponent = ref(null);
const osComponentMaxScrollHeight = ref(null);
const isLoading = ref(true);
const showForm = ref(false);

const options = {
  scrollbars: {
    autoHide: 'scroll'
  }
};

const events = {
  scroll: () => {
    const { viewport } = osComponent.value.osInstance().elements();
    const { scrollHeight, offsetHeight, scrollTop } = viewport;
    initializeScrollListener(scrollHeight, offsetHeight, scrollTop);
  }
};

const handleLeakSubmitted = (newLeak) => {
  showForm.value = false;
  leaks.value.unshift(newLeak);
  fetchLeaks();
};

const fetchLeaks = async () => {
  isLoading.value = true;
  try {
    const response = await getLatestSelfReportedLeaks(props.accountUrl);
    leaks.value = response.data;
  } catch (error) {
    console.error('Error fetching leaks:', error);
  } finally {
    isLoading.value = false;
  }
};

const initializeScrollListener = (scrollHeight, offsetHeight, scrollTop) => {
  hideScrollHintBottom.value = scrollHeight - offsetHeight <= scrollTop;
  showScrollHintTop.value = scrollTop > 0;
};

onBeforeMount(() => {
  fetchLeaks();
});

watch(() => props.accountUrl, () => {
  fetchLeaks();
});
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>

