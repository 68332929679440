<template>
  <div class="rounded-md lb-shadow xl:col-span-1 md:overflow-hidden relative min-h-[460px]">
    <h3 class="flex items-center text-lg font-semibold relative bg-white px-6 py-4 rounded-t-md">
      {{ trans('leakblock.compactChat.recentChats') }}
    </h3>
    <div id="fullWidthTabContent" class="px-3 md:px-6 pt-3 pb-[60px] md:pb-[90px] h-[calc(100%-60px)] overflow-y-auto">
      <div v-if="isLoading" class="flex justify-center items-center h-full">
        <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
      <div v-else-if="sortedRecentChats.length > 0" class="h-full">
        <ul class="space-y-2.5">
          <li v-for="chat in sortedRecentChats" :key="chat.id" class="intro-y">
            <div class=" transition duration-300 hover:scale-[1.02] bg-white rounded-md shadow-sm">
              <router-link :to="{ name: 'messenger', query: { chatId: chat.identifier } }" class="block p-3">
                <div class="flex justify-between items-center">
                  <span class="font-medium truncate">{{ getChatTitle(chat) }}</span>
                  <span class="text-xs text-gray-500">{{ formatDate(chat.lastMessage?.createdAt || chat.createdAt) }}</span>
                </div>
                <p class="text-sm text-gray-600 truncate">{{ chat.lastMessage?.content || 'No messages yet' }}</p>
              </router-link>
            </div>
          </li>
        </ul>
      </div>
      <div v-else class="flex justify-center items-center h-full">
        <p class="text-center text-gray-500 py-4">{{ trans('leakblock.compactChat.noChats') }}</p>
      </div>
    </div>
    <div class="flex w-full bg-transparent absolute bottom-0 left-0 lb-transparent-background h-[60px] border-t border-gray-200 rounded-b-md px-6 items-center">
      <div class="flex-shrink-0 ml-auto">
        <router-link :to="{ name: 'messenger' }" class="inline-flex items-center p-2 text-xs font-medium uppercase rounded-lg text-primary-700 sm:text-sm hover:bg-gray-100">
          {{ trans('leakblock.compactChat.toMessenger') }}
          <svg class="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
          </svg>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { trans } from "../../../../js/utilities/leakblock-translations";
import { formatDate } from "../../../../base";

const router = useRouter();
const recentChats = ref([]);
const isLoading = ref(true);

const sortedRecentChats = computed(() => {
  return [...recentChats.value].sort((a, b) => {
    const dateA = a.lastMessage ? new Date(a.lastMessage.createdAt) : new Date(a.createdAt);
    const dateB = b.lastMessage ? new Date(b.lastMessage.createdAt) : new Date(b.createdAt);
    return dateB - dateA;
  });
});

const fetchRecentChats = async () => {
  isLoading.value = true;
  try {
    const response = await axios.get('/api/recent-chats');
    recentChats.value = response.data;
  } catch (error) {
    console.error('Error fetching recent chats:', error);
  } finally {
    isLoading.value = false;
  }
};

const getChatTitle = (chat) => {
  if (chat.type === 'team') {
    return 'Leakblock Team';
  } else if (chat.type === 'leak' && chat.relatedLeak) {
    return `Leak: ${chat.relatedLeak.website}`;
  }
  return 'Chat';
};

onMounted(() => {
  fetchRecentChats();
});
</script>